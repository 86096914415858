@tailwind base;
@tailwind components;
@tailwind utilities;
.fade {
  background: rgba(235, 235, 235, 0.26);
  backdrop-filter: blur(5px);
}

.custom-datepicker-zindex {
  z-index: 1050 !important; /* Set higher than your modal z-index */
  position: relative; /* Ensure proper positioning */
}

.gpdyeS {
  max-width: 100% !important;
  max-height: 80vh !important;
  padding-right: 124px;
  height: 70vh !important;
  height: 100%;
}
.gpdyeS::-webkit-scrollbar {
  width: 8px;
}

.gpdyeS::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.gpdyeS::-webkit-scrollbar-thumb {
  background-color: #26bbdd;
  border-radius: 4px;
}

.gpdyeS::-webkit-scrollbar-thumb:hover {
  background-color: #26bbdd;
}
@keyframes popInUser {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes popInOther {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.mhgi {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.7)
    ),
    url(../src/assets/MH\ GAI\ LOGO\ SVG\ \ 2.png);

  background-repeat: no-repeat;
  background-position: center;
}
.host {
  background: #fff;
  width: 100%;
  height: 100px;
  margin: auto;
  padding-top: 35px;
}

.loading {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  display: inline-block; /* Use inline-block instead of float */
  margin-right: 5px;
}

.loading-0 {
  animation: bounce 1s infinite;
  animation-delay: 0.1s;
  background: #18829a;
}

.loading-1 {
  animation: bounce 1s infinite;
  animation-delay: 0.3s;
  background: #18829a;
}

.loading-2 {
  animation: bounce 1s infinite ease;
  animation-delay: 0.5s;
  background: #18829a;
}

.react-datepicker-wrapper {
  width: 100%;
}

input::file-selector-button {
  background-color: #26bbdd !important;
}

@keyframes bounce {
  0%,
  100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}

.select__control {
  background-color: #f9fafb !important;
  height: 50px !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color) !important;
}
.editfrom {
  height: 50vh !important;
}
.select2 {
  width: 100% !important;
}
.select2-selection__rendered {
  padding-left: 24px !important;
  padding-right: 20px;
  padding-top: 10px !important;
}
.select2-selection--single {
  /* background-color: #fff; */
  border: 0ch solid #aaa !important;
  background-color: #f9fafb !important;
  border-radius: 10px !important;
  height: 52px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
  font-size: 16px !important;
  height: 52px !important;
}

.css-13cymwt-control {
  border: 0ch solid #aaa !important;
  background-color: #f9fafb !important;
  border-radius: 10px !important;
  height: 52px !important;
}
.css-t3ipsp-control {
  border: 0ch solid #aaa !important;
  background-color: #f9fafb !important;
  border-radius: 10px !important;
  height: 52px !important;
}
.ModalHeight {
  height: 60vh;
}

/* Width of the scrollbar */
.custom-scroll-bar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.custom-scroll-bar::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 5px;
}

/* Handle */
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: #29bcdd;
  border-radius: 5px;
}

/* Width of the scrollbar */
.chat-scroll-bar::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.chat-scroll-bar::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 5px;
}

/* Handle */
.chat-scroll-bar::-webkit-scrollbar-thumb {
  background: #29bcdd;
  border-radius: 5px;
  height: 100px;
}

.select-selection__input:focus {
  box-shadow: none;
}

/* .select-selection__value-container {
  flex-wrap: nowrap !important;
  overflow: hidden;
  max-width: 500px;
}

.select-selection__multi-value {
  max-width: 250px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-selection__multi-value__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-selection__control {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
} */

.selected-page-style {
  background-color: #26bbdd; /* Tailwind's blue-500 */
  color: white;
}

.custom-checkbox:checked {
  background-color: #26bbdd;
  border-color: #26bbdd;
}

@keyframes fillAnimation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}

.fill-animation {
  animation: fillAnimation 1s ease-in-out infinite;
}

.liquidity-planner-app .disabled {
  color: #666;
  background-color: #8181810e;
}

.liquidity-planner-app .reactgrid-content .rg-pane .rg-cell {
  padding: 8px 16px;
  font-size: 12px;
}

.liquidity-planner-app .padding-left-lg {
  padding-left: 24px !important;
}

.liquidity-planner-app .justify-content-center {
  justify-content: center;
}

.liquidity-planner-app .justify-content-end {
  justify-content: flex-end;
}

.liquidity-planner-app .align-items-end {
  align-items: flex-end !important;
}

.liquidity-planner-app .font-bold {
  font-weight: 600;
}

.liquidity-planner-app .text-red {
  color: #cf1322;
}

.liquidity-planner-app .text-blue {
  color: #2797ff;
}

.liquidity-planner-app .text-green {
  color: #107c41;
}

.liquidity-planner-app .light-green-bg {
  background-color: #e7f2ec;
}

.liquidity-planner-app .text-md {
  font-size: 14px !important;
}

.liquidity-planner-app .text-lg {
  font-size: 16px !important;
}

.liquidity-planner-app .rg-celleditor {
  font-size: 12px;
  padding: 8px 16px;
  border-width: 1px;
  box-shadow: 0px 0px 8px #2797ff;
}

.liquidity-planner-app .rg-celleditor input {
  font-family: "DM Sans", sans-serif;
}

.liquidity-planner-app .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}

.liquidity-planner-app .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}

@keyframes fade-in-infinite {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

.animate-fade-in-infinite {
  animation: fade-in-infinite 5s ease-in-out infinite;
}

.scrollbar-white {
  scrollbar-width: 0px; /* Firefox */
  scrollbar-color: #f9fafb transparent;
}

.scrollbar-white::-webkit-scrollbar {
  width: 0px;
}

.scrollbar-white::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-white::-webkit-scrollbar-thumb {
  background: #f9fafb;
  border-radius: 6px;
}
